// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCT07Yv0SiPiVRJO6wiHdbYGgMxxAOQXuA",
  authDomain: "arotec-ce100.firebaseapp.com",
  projectId: "arotec-ce100",
  storageBucket: "arotec-ce100.appspot.com",
  messagingSenderId: "86260932644",
  appId: "1:86260932644:web:28a2ec393e6202e3b3f8b1",
  measurementId: "G-Q5LZ2H5Y4P"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();